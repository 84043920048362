import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const getMainRoutes = async () => {
  // return [{"label":"Home","exact":true,"path":"/","auth":true},{"label":"Authentication/Logout","exact":true,"path":"/cikis-yap","auth":true},{"label":"Authentication/Login","exact":true,"path":"/giris-yap","auth":false},{"label":"Authentication/Register","exact":true,"path":"/kayit-ol","auth":"disable"},{"label":"Cart","exact":true,"path":"/sepetim","auth":true},{"label":"Discover","exact":true,"path":"/kesfet","auth":true},{"label":"NewProducts","exact":true,"path":"/yeni-urunler","auth":true},{"label":"Product","exact":true,"path":"/urun/:slug","auth":true},{"label":"Category","exact":true,"path":"/kategori/:slug","auth":true}]
  return await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API + process.env.REACT_APP_GET_ROUTES}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Target-URL": `${
        process.env.REACT_APP_API + process.env.REACT_APP_GET_ROUTES
      }`,
    },
  }).then((res) => {
    localStorage.setItem("mervebayiroutes", JSON.stringify(res.data));
    return res.data;
  });
};

export const auth = createSlice({
  name: "auth",
  initialState: {
    currency:
      localStorage.getItem("currency") != null &&
      localStorage.getItem("currency") != undefined
        ? localStorage.getItem("currency")
        : "TL",
    routes:
      localStorage.getItem("mervebayiroutes") != null &&
      localStorage.getItem("mervebayiroutes") != undefined
        ? JSON.parse(localStorage.getItem("mervebayiroutes"))
        : getMainRoutes(),
    user: [],
    balance: 0,
    login: localStorage.getItem("login") == "true" ? true : false,
    token:
      localStorage.getItem("mervebayitoken") != null &&
      localStorage.getItem("mervebayitoken") != "" &&
      localStorage.getItem("mervebayitoken") != undefined
        ? localStorage.getItem("mervebayitoken")
        : null,
  },
  reducers: {
    loginChange: (state, action) => {
      state.login = true;
      localStorage.setItem("login", action.payload.login);

      // state.user = action.payload.user
      // if (action.payload.user.status == 'Yönetici' || action.payload.user.status == 'Satıcı') {
      //     state.admin = true
      //     localStorage.setItem('admin', true)
      // }
      // localStorage.setItem('user', JSON.stringify(action.payload.user))

      state.token = action.payload.token;
      localStorage.setItem("mervebayitoken", action.payload.token);
      localStorage.removeItem("cart");
    },
    newCurrencySet: (state, action) => {
      state.currency = action.payload;
      localStorage.setItem("currency", action.payload);
    },
    setCustomer: (state, action) => {
      console.log("ac:", action.payload);
      localStorage.setItem("balance", action.payload.balance);
      localStorage.setItem("currency", action.payload.visibleCurrencyUnit);
      localStorage.setItem("customer", JSON.stringify(action.payload));
      localStorage.setItem("title", action.payload.title);
      localStorage.setItem("cargoCostLimit", action.payload.cargoCostLimit);
      localStorage.setItem("id", action.payload.customerId);
      state.user = action.payload;
      state.balance = action.payload.balance;
      state.currency = action.payload.visibleCurrencyUnit;
    },
    logout: (state) => {
      state.login = false;
      state.currency = null;
      state.token = null;
      state.admin = false;
      state.user = [];
      state.balance = 0;
      localStorage.clear();
      localStorage.removeItem("cart");
      localStorage.removeItem("cargoCostLimit");
      localStorage.removeItem("customer");
      localStorage.removeItem("balance");
      localStorage.removeItem("id");
      localStorage.removeItem("currency");
      localStorage.removeItem("user");
      localStorage.removeItem("login");
      localStorage.removeItem("mervebayitoken");
      localStorage.removeItem("admin");
    },
  },
});

export const { loginChange, newCurrencySet, logout, setCustomer } =
  auth.actions;
export default auth.reducer;
