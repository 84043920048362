import axios from 'axios'

export const useProductRequests = () => {
  
  const getProducts = async (data={}) => { 
    /*
      page=${data.page||"1"}&
      name=${data.name||""}&
      description=${data.description||""}&
      price=${data.price||""}&
      stock=${data.stock||""}&
      slug=${data.slug||""}&
      categorySlug=${data.category||""}&
      sort=${data.sort||""}&
      allSearch=${data.allSearch||""}
    */
    
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}`,
      data:data,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}/?count=10`,
        'Authorization': `${localStorage.getItem('mervebayitoken')}`
      }
    })
  }
  const getMainProductRequest = async (data) => {
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_MAIN_PRODUCTS
      }?type=${data.type}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_MAIN_PRODUCTS
        }/?count=10`,
        Authorization: `${localStorage.getItem("mervebayitoken")}`,
      },
    });
  }
  const getMainProducts = async (data = {}) => {
    const cached = localStorage.getItem("sasamainproducts" + (data.type+""));
    var currentDate = new Date();
    var secondsSinceEpoch = currentDate.getTime() / 1000;
    if (cached != null) {
      const check = await JSON.parse(cached);
      if (check.timestamp > secondsSinceEpoch) {
        return check.data;
      }
    }
    const result = await getMainProductRequest(data);
    localStorage.setItem(
      "sasamainproducts" + (data.type + ""),
      JSON.stringify({
        timestamp: secondsSinceEpoch + 60 * 60,
        data: result,
      })
    );
    return result;
  };

  const getProduct = async (id) => { 
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCT}?slug=${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}/${id}`,
        'Authorization': `${localStorage.getItem('mervebayitoken')}`
      }
    })
  }

  

  const getDiscover = async (data={}) => {
    
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS}`,
      data:data,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `${localStorage.getItem('mervebayitoken')}`
      }
    })
  }

  const getCategoryRequest = async () => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_CATEGORIES}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `${localStorage.getItem("mervebayitoken")}`,
      },
    });
  }

  const getCategories = async (count=null) => {
    
    const cached = localStorage.getItem("sasacategories" );
    var currentDate = new Date();
    var secondsSinceEpoch = currentDate.getTime() / 1000;
    if (cached != null) {
      const check = await JSON.parse(cached);
      if (check.timestamp > secondsSinceEpoch) {
        return check.data;
      }
    }
    const result = await getCategoryRequest();
    localStorage.setItem(
      "sasacategories" ,
      JSON.stringify({
        timestamp: secondsSinceEpoch + 60 * 60,
        data: result,
      })
    );
    return result;

  }



  const getBrands = async (count=null) => {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_BRANDS}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `${localStorage.getItem('mervebayitoken')}`

      }
    })
  }
  const getCampaignRequest = async (type) => {
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_GET_IMAGES
      }?pass=ds.is.selected.2023&type=${type}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `${localStorage.getItem("mervebayitoken")}`,
      },
    });
    
  }
  const getCampaigns = async (type = "campaign") => {
    const cached = localStorage.getItem(
      "sasacampaigns"+type
    );
    var currentDate = new Date();
    var secondsSinceEpoch = currentDate.getTime() / 1000;
    if (cached != null) {
      const check = await JSON.parse(cached);
      if (check.timestamp > secondsSinceEpoch) {
        return check.data;
      }
    }
    const result = await getCampaignRequest(type);
    localStorage.setItem(
      "sasacampaigns" + type,
      JSON.stringify({
        timestamp: secondsSinceEpoch + 60 * 60,
        data: result,
      })
    );
    return result;
  };
  
  return {
    getProducts,
    getProduct,
    getDiscover,
    getCategories,
    getBrands,
    getMainProducts,
    getCampaigns,
  };
}