import React, { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { SlideProductContainer } from "../styles/Home";
import SlideProducts from "../components/Slider/Products";
import Header from "../components/Navigation/Header";
import BottomNav from "../components/Navigation/Bottom";
import Footer from "../components/Navigation/Footer";
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader";

import { useProductRequests } from "../helpers/ProductRequests";

import PaymentItems from "../components/Cart/Payment";

export default function Basket(props) {
  const { getDiscover } = useProductRequests();

  const { basket } = useSelector((state) => state.cart);

  const [discover, setDiscover] = useState([]);

  useLayoutEffect(() => {
    if (discover.length === 0) {
      getDiscover({ page: 1, count: 20, sort: "RANDOM", sortedBy: "" })
        .then((response) => {
          setDiscover(response.data.data.items);
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    }
  }, []);

  return (
    <>
      <Header />

      <div>
        <PaymentItems cart={basket} />
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
