import React from 'react'
import {NotFoundContainer} from '../styles/Content'
export default function NotFound() {
  return (
    <NotFoundContainer>
      <div className="fon">
        <div className="horizon"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      <div className="hill"></div>

      <span className="not-found">Sayfa Bulunamadı</span>
      <span className="page-404">Hata 404</span>
      <div className="moon-sky"></div>
      <div className="satellite">☄</div>
        <div className="meteores">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
      <div className="cosmos-star">
        <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      </div>
      <div className="my-cat">
          <div className="ear-l">
              <div className="ear-fur-l"></div>
          </div>
          <div className="ear-r">
              <div className="ear-fur-r"></div>
          </div>
      <div className="head-cat">
        <div className="tabby-cat"></div>
        <div className="tabby-cat-1"></div>
        <div className="tabby-cat-2"></div>
          <div className="eye-l">
              <div className="eye-lz"></div>
          </div>
          <div className="cat-nose"></div>
          <div className="eye-r">
              <div className="eye-rz"></div>
          </div> 
          <div className="muzzle-cat"></div>
        <div className="	jaws-cat"></div>
        <div className="whiskers">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
        <div className="body-cat"></div>
        <div className="body-cat-1"></div>
        <div className="paw-cat-l"><div></div></div>
        <div className="paw-cat-r"><div></div></div>
        <div className="tail-cat"></div>
      </div>
      <div className="rock">
      <div className="rock-mountain_1"></div>
      <div className="rock-mountain_s_1"></div>
      <div className="rock-mountain_2"></div>
      <div className="rock-mountain_s_2"></div>
      <div className="rock-mountain_3"></div>
      <div className="rock-mountain_s_3"></div>
      </div>
      </div>
    </NotFoundContainer>
  )
}
