import React from "react";
import Header from "../../components/Navigation/Header";
import { CallBackContainer } from "../../styles/Content";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { emptyCart } from "../../components/stores/Cart";

export default function SuccessCallback() {
  const [update,setUpdate]=React.useState(false)
  const dispatch = useDispatch();
  const clearAll=()=>{
    setUpdate(true)
    localStorage.removeItem('cart')
    console.log(localStorage.getItem('cart'))
    dispatch(emptyCart());
  }
  React.useEffect(() => {
    if(update==false){
      clearAll()
    }
  }, [update])
  return (
    <>
      <Header />

      <CallBackContainer>
        <div className="main-container">
          <div className="check-container">
            <div className="check-background">
              <svg
                viewBox="0 0 65 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 25L27.3077 44L58.5 7"
                  stroke="white"
                  strokeWidth="13"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="check-shadow"></div>
          </div>

          <h1>Sipariş Gönderimi Başarılı</h1>
          <div className="buttons">
            <Link to="/">Ana Sayfa</Link>
            <Link to="/siparisler">Siparişlerim</Link>
          </div>
        </div>
      </CallBackContainer>
    </>
  );
}
