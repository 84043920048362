import React, { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { SlideProductContainer } from "../styles/Home";
import SlideProducts from "../components/Slider/Products";
import Header from "../components/Navigation/Header";
import BottomNav from "../components/Navigation/Bottom";
import Footer from "../components/Navigation/Footer";
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader";

import { useProductRequests } from "../helpers/ProductRequests";
import { useOrderRequests } from "../helpers/OrderRequests";
import { InvoiceContainer } from "../styles/Product";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Currency } from "react-intl-number-format/dist/index.js";
import { Button } from "react-bootstrap";
import { Sentry } from "react-activity";

export default function Basket(props) {
  const { id } = useParams();

  const { getMainProducts } = useProductRequests();
  const { getInvoice } = useOrderRequests();

  const { basket } = useSelector((state) => state.cart);

  const [discover, setDiscover] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    if (discover.length === 0) {
      getMainProducts({ type: 1 })
        .then((response) => {
          setDiscover(response.data.data.items);
        })
        .catch((error) => {});
    }
  }, []);
  const [printOn, setPrintOn] = React.useState(false);
  const printPdf = () => {
    setPrintOn(true);
    setTimeout(() => {
      setPrintOn(false);
    }, 5500);
  };
  useLayoutEffect(() => {
    if (invoice.length === 0) {
      getInvoice(id)
        .then((response) => {
          setInvoice(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    }
  }, []);
  const columns = [
    {
      name: "",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Ad",
      selector: (row) => row.title,
    },
    {
      name: "Miktar",
      selector: (row) => row.quantity,
      width: "150px",
    },
    {
      name: "Birim",
      selector: (row) => row.unit,
      width: "150px",
    },
    {
      name: "İskonto",
      selector: (row) => '%'+row.discountRate,
      width: "150px",
    },
    {
      name: "Fiyat",
      selector: (row) => <Currency>{row.price}</Currency>,
      width: "150px",
    },
    {
      name: "Toplam",
      selector: (row) => <Currency>{row.totalPriceWithVat}</Currency>,
      width: "150px",
    },
  ];
  return (
    <>
      {printOn ? (
        <>
          <iframe
            src={"/yazdir/faturalar/" + id}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              height: 0,
              width: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Header />

      <div>
        <SlideProductContainer>
          <InvoiceContainer>
            <div
              style={{
                width: "100%",
                minHeight: 50,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  color: "#000",
                  border: "none",
                  outline: "1px solid lightblue",
                  marginRight: 20,
                  height: 30,
                  width: 70,
                }}
                onClick={() => {
                  if (!printOn) {
                    printPdf();
                  }
                }}
              >
                {printOn ? <Sentry /> : "PDF"}
              </button>
            </div>

            {invoice == null || (invoice.length === 0 && loading) ? (
              <>
                <div className="loader">
                  <ContentLoader
                    speed={0.5}
                    width={2500}
                    height={160}
                    viewBox="0 0 2400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <rect
                      x="-25"
                      y="0"
                      rx="3"
                      ry="3"
                      width="2429"
                      height="34"
                    />
                    <rect
                      x="-28"
                      y="48"
                      rx="3"
                      ry="3"
                      width="2437"
                      height="35"
                    />
                    <rect
                      x="-29"
                      y="96"
                      rx="3"
                      ry="3"
                      width="2441"
                      height="35"
                    />
                  </ContentLoader>
                </div>
                <div className="loader" style={{ marginTop: -100 }}>
                  <ContentLoader
                    speed={0.5}
                    width={2500}
                    height={160}
                    viewBox="0 0 2400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <rect
                      x="-25"
                      y="0"
                      rx="3"
                      ry="3"
                      width="2429"
                      height="34"
                    />
                    <rect
                      x="-28"
                      y="48"
                      rx="3"
                      ry="3"
                      width="2437"
                      height="35"
                    />
                    <rect
                      x="-29"
                      y="96"
                      rx="3"
                      ry="3"
                      width="2441"
                      height="35"
                    />
                  </ContentLoader>
                </div>
              </>
            ) : (
              <>
                <div className="head">
                  <div className="leftHead">
                    <div>
                      <span>Tarih</span>
                      <span>:</span>
                      <span>{invoice.invoice.date}</span>
                    </div>

                    <div>
                      <span>Sipariş No</span>
                      <span>:</span>
                      <span>{invoice.invoice.orderCode}</span>
                    </div>

                    <div>
                      <span>Durum</span>
                      <span>:</span>
                      <span>{invoice.invoice.status}</span>
                    </div>
                  </div>

                  <div className="rightHead">
                    <h4 style={{color: "#000"}}>
                      {JSON.parse(localStorage.getItem("customer")).title}
                    </h4>
                    {invoice.invoice.image != null && (
                      <img
                        onContextMenu={() => false}
                        src={invoice.invoice.image}
                        height={70}
                      />
                    )}
                  </div>
                </div>

                <DataTable columns={columns} data={invoice.items} />

                <div className="total">
                  <div className="space"></div>
                  <div style={{ color: "#000" }}>
                    <span style={{ color: "#000" }}>Toplam Tutar</span>
                    <span style={{ color: "#000" }}> :</span>
                    <Currency style={{ color: "#000" }}>
                      {invoice.invoice.totalPrice}
                    </Currency>
                  </div>
                </div>

                {/* <Button>YAZDIR</Button> */}
              </>
            )}
          </InvoiceContainer>
        </SlideProductContainer>
{/* 
        <SlideProductContainer>
          <h1
            style={{
              color: "rgba(255,10,0)", textAlign: "center",
              paddingTop: 40,
              fontSize: 30,
              marginBottom: 8,
              fontWeight: "bold",
            }}
            // onClick={() => history.push("/yeni-urunler")}
          >
            Kampanyalı Ürünler
          </h1>

          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer> */}
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
