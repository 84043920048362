import React, { useState, useLayoutEffect } from "react";

import { useProductRequests } from "../../helpers/ProductRequests";
import { BigCategoryContainer } from "../../styles/Home";
import BigCategoryItem from "../Content/Category/BigCategoryItem";
import { faAngleRight, faArrowRight, faBars, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from 'react-router-dom';

export default function BigCategories({open,setOpen}) {
      
  const { getCategories, getCampaigns, getBrands } = useProductRequests();
  const history=useHistory()
  const [categories, setCategories] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  React.useEffect(() => {
    if(categories.length != 0){
      setOpen(true)
    }
  }, [categories]);

  useLayoutEffect(() => {
    if (categories.length === 0) {
      getBrands().then(res=>{
        setCategories(res.data.data);
      });
      
      getCampaigns()
        .then((response) => {
          setCampaigns(response.data.images);
        })
        .catch((error) => {
          console.log("error", error.response);
        });
    }
  }, []);
  return (
    <BigCategoryContainer open={open}>
      <div
        className="head"
        onClick={() => {
          if (categories.length > 0) {
            setOpen(!open);
          }
        }}
      >
        <FontAwesomeIcon icon={faBars} /> <span>{open && "KATEGORİLER"}</span>
      </div>
      {open && (
        <div className="category-item-list">
          <div
            style={{ backgroundColor: "rgba(255,0,0,0.01)" }}
            className="category-item"
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              onClick={() => history.push(`/kampanyali-urunler`)}
            >
              <div
                style={{
                  color: "red",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <span style={{color:'red'}}>Kampanyalı Ürünler</span>
                <FontAwesomeIcon icon={faStar} color="red" />
              </div>
            </span>
          </div>
          {categories.map((item, index) => (
            <div key={item.slug} className={"category-item "+(window.location.href.indexOf("marka/"+item.slug)!=-1 ? "active-category" : "")} >
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
                onClick={() => history.push(`/marka/${item.slug}`)}
              >
                <div>{item.name} </div>
              </span>
            
            </div>
          ))}
          {campaigns.map((item, index) => {
            return (
              <div key={"campaign-" + index} className="category-item">
                <img src={item + "?ordu=" + 5252} style={{ width: "100%" }} />
              </div>
            );
          })}
        </div>
      )}
    </BigCategoryContainer>
  );
}
