import axios from 'axios'

export const useAuthRequests = () => {

  const loginRequest = async (email,password) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_LOGIN}`,
      data: {
        username: email,
        password: password
      },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_LOGIN}`
      }
    })
  }
  const changePasswordRequest = async (data) => {
    return axios({
      method: "post",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_CHANGE_PASSWORD
      }`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_CHANGE_PASSWORD
        }`,
        Authorization: `${localStorage.getItem("mervebayitoken")}`,
      },
    });
  };

  ;
  const getCustomer=async (token)=>{
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API+process.env.REACT_APP_CUSTOMER}`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Target-URL' : `${process.env.REACT_APP_API+process.env.REACT_APP_REGISTER}`,
        'Authorization': `${token}`
      }
    })
  }
  const registerRequest = async (
    data
  ) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API + process.env.REACT_APP_REGISTER}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_REGISTER
        }`,
      },
    });
  };
  const setCurrency = async (currency) => {
    return axios({
      method: "post",
      url: `${
        process.env.REACT_APP_API + process.env.REACT_APP_SET_CURRENCY
      }?currencyUnit=${currency}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("mervebayitoken")}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Target-URL": `${
          process.env.REACT_APP_API + process.env.REACT_APP_SET_CURRENCY
        }`,
      },
    });
  };

  
  return {
    loginRequest,
    registerRequest,
    getCustomer,
    setCurrency,
    changePasswordRequest,
  };
}