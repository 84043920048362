import React, { useState, useLayoutEffect } from "react";
import { SlideProductContainer } from "../styles/Home";
import SlideProducts from "../components/Slider/Products";
import Header from "../components/Navigation/Header";
import BottomNav from "../components/Navigation/Bottom";
import Footer from "../components/Navigation/Footer";
import "react-activity/dist/Sentry.css";

export default function GizlilikPolitikasi(props) {

  const PrivacyPolicy = () => {
    return (
      <div style={styles.container}>
        <h1 style={styles.heading}>Gizlilik Politikası</h1>
        <p class="p1">
          <strong>GİZLİLİK VE G&Uuml;VENLİK POLİTİKASI</strong>
        </p>
        <p class="p1">
          <strong>&nbsp;</strong>
        </p>
        <p class="p1">
          <strong>
            Mağazamızda verilen t&uuml;m servisler ve www.mervebayi.com
            adresinde kayıtlı &nbsp;MERVE BASIM YAYIN DAĞITIM SAN.TİC.LTD.ŞTİ.
            firmamıza aittir ve firmamız tarafından işletilir.&nbsp;
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Firmamız, &ccedil;eşitli ama&ccedil;larla kişisel veriler
            toplayabilir. Aşağıda, toplanan kişisel verilerin nasıl ve ne
            şekilde toplandığı, bu verilerin nasıl ve ne şekilde korunduğu
            belirtilmiştir.&nbsp;
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            &Uuml;yelik veya Mağazamız &uuml;zerindeki &ccedil;eşitli form ve
            anketlerin doldurulması suretiyle &uuml;yelerin kendileriyle ilgili
            bir takım kişisel bilgileri (isim-soy isim, firma bilgileri,
            telefon, adres veya e-posta adresleri gibi) Mağazamız tarafından
            işin doğası gereği toplanmaktadır.&nbsp;
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Firmamız bazı d&ouml;nemlerde m&uuml;şterilerine ve &uuml;yelerine
            kampanya bilgileri, yeni &uuml;r&uuml;nler hakkında bilgiler,
            promosyon teklifleri g&ouml;nderebilir. &Uuml;yelerimiz bu gibi
            bilgileri alıp almama konusunda her t&uuml;rl&uuml; se&ccedil;imi
            &uuml;ye olurken yapabilir, sonrasında &uuml;ye girişi yaptıktan
            sonra hesap bilgileri b&ouml;l&uuml;m&uuml;nden bu se&ccedil;imi
            değiştirilebilir ya da kendisine gelen bilgilendirme iletisindeki
            linkle bildirim yapabilir.&nbsp;
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Mağazamız &uuml;zerinden veya eposta ile ger&ccedil;ekleştirilen
            onay s&uuml;recinde, &uuml;yelerimiz tarafından mağazamıza
            elektronik ortamdan iletilen kişisel bilgiler, &Uuml;yelerimiz ile
            yaptığımız "Kullanıcı S&ouml;zleşmesi" ile belirlenen ama&ccedil;lar
            ve kapsam dışında &uuml;&ccedil;&uuml;nc&uuml; kişilere
            a&ccedil;ıklanmayacaktır.
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile ilgili
            &ccedil;ıkabilecek sorunların veya uyuşmazlıkların hızla
            &ccedil;&ouml;z&uuml;lmesi i&ccedil;in, Firmamız, &uuml;yelerinin IP
            adresini kaydetmekte ve bunu kullanmaktadır. IP adresleri,
            kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik
            bilgi toplamak amacıyla da kullanılabilir.
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Firmamız, &Uuml;yelik S&ouml;zleşmesi ile belirlenen ama&ccedil;lar
            ve kapsam dışında da, talep edilen bilgileri kendisi veya işbirliği
            i&ccedil;inde olduğu kişiler tarafından doğrudan pazarlama yapmak
            amacıyla kullanabilir. &nbsp;Kişisel bilgiler, gerektiğinde
            kullanıcıyla temas kurmak i&ccedil;in de kullanılabilir. Firmamız
            tarafından talep edilen bilgiler veya kullanıcı tarafından sağlanan
            bilgiler veya Mağazamız &uuml;zerinden yapılan işlemlerle ilgili
            bilgiler; Firmamız ve işbirliği i&ccedil;inde olduğu kişiler
            tarafından, "&Uuml;yelik S&ouml;zleşmesi" ile belirlenen
            ama&ccedil;lar ve kapsam dışında da, &uuml;yelerimizin kimliği ifşa
            edilmeden &ccedil;eşitli istatistiksel değerlendirmeler, veri tabanı
            oluşturma ve pazar araştırmalarında kullanılabilir.
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Firmamız, gizli bilgileri kesinlikle &ouml;zel ve gizli tutmayı,
            bunu bir sır saklama y&uuml;k&uuml;m&uuml; olarak addetmeyi ve
            gizliliğin sağlanması ve s&uuml;rd&uuml;r&uuml;lmesi, gizli bilginin
            tamamının veya herhangi bir kısmının kamu alanına girmesini veya
            yetkisiz kullanımını veya &uuml;&ccedil;&uuml;nc&uuml; bir kişiye
            ifşasını &ouml;nlemek i&ccedil;in gerekli t&uuml;m tedbirleri almayı
            ve gerekli &ouml;zeni g&ouml;stermeyi taahh&uuml;t etmektedir.
          </strong>
        </p>
        <p class="p1">
          <strong>&nbsp;</strong>
        </p>
        <p class="p1">
          <strong>KREDİ KARTI G&Uuml;VENLİĞİ</strong>
        </p>
        <p class="p1">
          <strong>&nbsp;</strong>
        </p>
        <p class="p1">
          <strong>
            Firmamız, alışveriş sitelerimizden alışveriş yapan kredi kartı
            sahiplerinin g&uuml;venliğini ilk planda tutmaktadır. Kredi kartı
            bilgileriniz hi&ccedil;bir şekilde sistemimizde saklanmamaktadır.
          </strong>
        </p>
        <p class="p1">
          <strong>&nbsp;</strong>
        </p>
        <p class="p1">
          <strong>
            İşlemler s&uuml;recine girdiğinizde g&uuml;venli bir sitede
            olduğunuzu anlamak i&ccedil;in dikkat etmeniz gereken iki şey
            vardır. Bunlardan biri tarayıcınızın en alt satırında bulunan bir
            anahtar ya da kilit simgesidir. Bu g&uuml;venli bir internet
            sayfasında olduğunuzu g&ouml;sterir ve her t&uuml;rl&uuml;
            bilgileriniz şifrelenerek korunur. Bu bilgiler, ancak satış
            işlemleri s&uuml;recine bağlı olarak ve verdiğiniz talimat
            istikametinde kullanılır. Alışveriş sırasında kullanılan kredi kartı
            ile ilgili bilgiler alışveriş sitelerimizden bağımsız olarak 128 bit
            SSL (Secure Sockets Layer) protokol&uuml; ile şifrelenip sorgulanmak
            &uuml;zere ilgili bankaya ulaştırılır. Kartın kullanılabilirliği
            onaylandığı takdirde alışverişe devam edilir. Kartla ilgili
            hi&ccedil;bir bilgi tarafımızdan
            g&ouml;r&uuml;nt&uuml;lenemediğinden ve kaydedilmediğinden,
            &uuml;&ccedil;&uuml;nc&uuml; şahısların herhangi bir koşulda bu
            bilgileri ele ge&ccedil;irmesi engellenmiş olur.
          </strong>
        </p>
        <p class="p1">
          <strong>
            Online olarak kredi kartı ile verilen siparişlerin
            &ouml;deme/fatura/teslimat adresi bilgilerinin g&uuml;venilirliği
            firmamiz tarafından Kredi Kartları Dolandırıcılığı'na karşı
            denetlenmektedir. Bu y&uuml;zden, alışveriş sitelerimizden ilk defa
            sipariş veren m&uuml;şterilerin siparişlerinin tedarik ve teslimat
            aşamasına gelebilmesi i&ccedil;in &ouml;ncelikle finansal ve
            adres/telefon bilgilerinin doğruluğunun onaylanması gereklidir. Bu
            bilgilerin kontrol&uuml; i&ccedil;in gerekirse kredi kartı sahibi
            m&uuml;şteri ile veya ilgili banka ile irtibata
            ge&ccedil;ilmektedir.
          </strong>
        </p>
        <p class="p1">
          <strong>
            &Uuml;ye olurken verdiğiniz t&uuml;m bilgilere sadece siz ulaşabilir
            ve siz değiştirebilirsiniz. &Uuml;ye giriş bilgilerinizi
            g&uuml;venli koruduğunuz takdirde başkalarının sizinle ilgili
            bilgilere ulaşması ve bunları değiştirmesi m&uuml;mk&uuml;n
            değildir. Bu ama&ccedil;la, &uuml;yelik işlemleri sırasında 128 bit
            SSL g&uuml;venlik alanı i&ccedil;inde hareket edilir. Bu sistem
            kırılması m&uuml;mk&uuml;n olmayan bir uluslararası bir şifreleme
            standardıdır.
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Bilgi hattı veya m&uuml;şteri hizmetleri servisi bulunan ve
            a&ccedil;ık adres ve telefon bilgilerinin belirtildiği İnternet
            alışveriş siteleri g&uuml;n&uuml;m&uuml;zde daha fazla tercih
            edilmektedir. Bu sayede aklınıza takılan b&uuml;t&uuml;n konular
            hakkında detaylı bilgi alabilir, online alışveriş hizmeti sağlayan
            firmanın g&uuml;venirliği konusunda daha sağlıklı bilgi
            edinebilirsiniz.&nbsp;
          </strong>
        </p>
        <p class="p1">
          <strong>&nbsp;</strong>
        </p>
        <p class="p1">
          <strong>
            Not: İnternet alışveriş sitelerinde firmanın a&ccedil;ık adresinin
            ve telefonun yer almasına dikkat edilmesini tavsiye ediyoruz.
            Alışveriş yapacaksanız alışverişinizi yapmadan &uuml;r&uuml;n&uuml;
            aldığınız mağazanın b&uuml;t&uuml;n telefon / adres bilgilerini not
            edin. Eğer g&uuml;venmiyorsanız alışverişten &ouml;nce telefon
            ederek teyit edin. Firmamıza ait t&uuml;m online alışveriş
            sitelerimizde firmamıza dair t&uuml;m bilgiler ve firma yeri
            belirtilmiştir.
          </strong>
        </p>
        <p class="p1">
          <strong>&nbsp;</strong>
        </p>
        <p class="p1">
          <strong>MAİL ORDER KREDİ KART BİLGİLERİ G&Uuml;VENLİĞİ</strong>
        </p>
        <p class="p1">
          <strong>&nbsp;</strong>
        </p>
        <p class="p1">
          <strong>
            Kredi kartı mail-order y&ouml;ntemi ile bize g&ouml;ndereceğiniz
            kimlik ve kredi kart bilgileriniz firmamız tarafından gizlilik
            prensibine g&ouml;re saklanacaktır. Bu bilgiler olası banka ile
            oluşubilecek kredi kartından para &ccedil;ekim itirazlarına karşı 60
            g&uuml;n s&uuml;re ile bekletilip daha sonrasında imha edilmektedir.
            Sipariş ettiğiniz &uuml;r&uuml;nlerin bedeli karşılığında bize
            g&ouml;ndereceğiniz tarafınızdan onaylı mail-order formu bedeli
            dışında herhangi bir bedelin kartınızdan &ccedil;ekilmesi halinde
            doğal olarak bankaya itiraz edebilir ve bu tutarın &ouml;denmesini
            engelleyebileceğiniz i&ccedil;in bir risk oluşturmamaktadır.&nbsp;
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            &Uuml;&Ccedil;&Uuml;NC&Uuml; TARAF WEB SİTELERİ VE UYGULAMALAR
          </strong>
        </p>
        <p class="p1">
          <strong>
            Mağazamız, &nbsp;web sitesi d&acirc;hilinde başka sitelere link
            verebilir. Firmamız, bu linkler vasıtasıyla erişilen sitelerin
            gizlilik uygulamaları ve i&ccedil;eriklerine y&ouml;nelik herhangi
            bir sorumluluk taşımamaktadır. Firmamıza ait sitede yayınlanan
            reklamlar, reklamcılık yapan iş ortaklarımız aracılığı ile
            kullanıcılarımıza dağıtılır. İş bu s&ouml;zleşmedeki Gizlilik
            Politikası Prensipleri, sadece Mağazamızın kullanımına ilişkindir,
            &uuml;&ccedil;&uuml;nc&uuml; taraf web sitelerini kapsamaz.&nbsp;
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>İSTİSNAİ HALLER</strong>
        </p>
        <p class="p1">
          <strong>
            Aşağıda belirtilen sınırlı hallerde Firmamız, işbu "Gizlilik
            Politikası" h&uuml;k&uuml;mleri dışında kullanıcılara ait bilgileri
            &uuml;&ccedil;&uuml;nc&uuml; kişilere a&ccedil;ıklayabilir. Bu
            durumlar sınırlı sayıda olmak &uuml;zere;
          </strong>
        </p>
        <p class="p1">
          <strong>
            1.Kanun, Kanun H&uuml;km&uuml;nde Kararname, Y&ouml;netmelik v.b.
            yetkili hukuki otorite tarafından &ccedil;ıkarılan ve
            y&uuml;r&uuml;rl&uuml;l&uuml;kte olan hukuk kurallarının getirdiği
            zorunluluklara uymak;
          </strong>
        </p>
        <p class="p1">
          <strong>
            2.Mağazamızın kullanıcılarla akdettiği "&Uuml;yelik
            S&ouml;zleşmesi"'nin ve diğer s&ouml;zleşmelerin gereklerini yerine
            getirmek ve bunları uygulamaya koymak amacıyla;
          </strong>
        </p>
        <p class="p1">
          <strong>
            3.Yetkili idari ve adli otorite tarafından usul&uuml;ne g&ouml;re
            y&uuml;r&uuml;t&uuml;len bir araştırma veya soruşturmanın
            y&uuml;r&uuml;t&uuml;m&uuml; amacıyla kullanıcılarla ilgili bilgi
            talep edilmesi;
          </strong>
        </p>
        <p class="p1">
          <strong>
            4.Kullanıcıların hakları veya g&uuml;venliklerini korumak
            i&ccedil;in bilgi vermenin gerekli olduğu hallerdir.&nbsp;
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>E-POSTA G&Uuml;VENLİĞİ</strong>
        </p>
        <p class="p1">
          <strong>
            Mağazamızın M&uuml;şteri Hizmetleri&rsquo;ne, herhangi bir
            siparişinizle ilgili olarak g&ouml;ndereceğiniz e-postalarda, asla
            kredi kartı numaranızı veya şifrelerinizi yazmayınız. E-postalarda
            yer alan bilgiler &uuml;&ccedil;&uuml;nc&uuml; şahıslar tarafından
            g&ouml;r&uuml;lebilir. Firmamız e-postalarınızdan aktarılan
            bilgilerin g&uuml;venliğini hi&ccedil;bir koşulda garanti edemez.
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>TARAYICI &Ccedil;EREZLERİ&nbsp;</strong>
        </p>
        <p class="p1">
          <strong>
            Firmamız, mağazamızı ziyaret eden kullanıcılar ve kullanıcıların web
            sitesini kullanımı hakkındaki bilgileri teknik bir iletişim dosyası
            (&Ccedil;erez-Cookie) kullanarak elde edebilir. Bahsi ge&ccedil;en
            teknik iletişim dosyaları, ana bellekte saklanmak &uuml;zere bir
            internet sitesinin kullanıcının tarayıcısına (browser)
            g&ouml;nderdiği k&uuml;&ccedil;&uuml;k metin dosyalarıdır. Teknik
            iletişim dosyası site hakkında durum ve tercihleri saklayarak
            İnternet'in kullanımını kolaylaştırır.
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Teknik iletişim dosyası, &nbsp;siteyi ka&ccedil; kişinin ziyaret
            ettiğini, bir kişinin siteyi hangi ama&ccedil;la, ka&ccedil; kez
            ziyaret ettiğini ve ne kadar sitede kaldıkları hakkında
            istatistiksel bilgileri elde etmeye ve kullanıcılar i&ccedil;in
            &ouml;zel tasarlanmış kullanıcı sayfalarından &nbsp;dinamik olarak
            reklam ve i&ccedil;erik &uuml;retilmesine yardımcı olur. Teknik
            iletişim dosyası, ana bellekte veya e-postanızdan veri veya başkaca
            herhangi bir kişisel bilgi almak i&ccedil;in tasarlanmamıştır.
            Tarayıcıların pek &ccedil;oğu başta teknik iletişim dosyasını kabul
            eder bi&ccedil;imde tasarlanmıştır ancak kullanıcılar dilerse teknik
            iletişim dosyasının gelmemesi veya teknik iletişim dosyasının
            g&ouml;nderildiğinde uyarı verilmesini sağlayacak bi&ccedil;imde
            ayarları değiştirebilirler.
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Firmamız, işbu "Gizlilik Politikası" h&uuml;k&uuml;mlerini dilediği
            zaman sitede yayınlamak veya kullanıcılara elektronik posta
            g&ouml;ndermek veya sitesinde yayınlamak suretiyle değiştirebilir.
            Gizlilik Politikası h&uuml;k&uuml;mleri değiştiği takdirde,
            yayınlandığı tarihte y&uuml;r&uuml;rl&uuml;k kazanır.
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Gizlilik politikamız ile ilgili her t&uuml;rl&uuml; soru ve
            &ouml;nerileriniz i&ccedil;in info@merveyayinevi.com adresine email
            g&ouml;nderebilirsiniz. Firmamız&rsquo;a ait aşağıdaki iletişim
            bilgilerinden ulaşabilirsiniz.
          </strong>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p1">
          <strong>
            Firma &Uuml;nvanı:&nbsp;MERVE BASIM YAYIN DAĞITIM SAN.TİC.LTD.ŞTİ.
          </strong>
        </p>
        <p class="p1">
          <strong>
            Adres:&nbsp;Maltepe Mah.Litros Yolu Sok. No. 12/160
            Zeytinburnu/İstanbul
          </strong>
        </p>
        <p class="p1">
          <strong>Eposta: </strong>
          <a href="mailto:info@merveyayinevi.com">
            <strong>info@merveyayinevi.com</strong>
          </a>
          <strong> &nbsp;</strong>
        </p>
        <p class="p1">
          <strong>Tel: 444 1 775</strong>
        </p>
      </div>
    );
  };

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      maxWidth: "800px",
      margin: "0 auto",
      marginTop:30,
      padding: "20px",
      backgroundColor: "#f9f9f9",
      border: "1px solid #ddd",
      borderRadius: "8px",
      color: "#000",
    },
    heading: {
      textAlign: "center",
      color: "#333",
    },
    section: {
      marginBottom: "20px",
      color:'#000',
    },
    subHeading: {
      color: "#555",
    },
    contact: {
      marginTop: "40px",
      borderTop: "1px solid #ddd",
      paddingTop: "20px",
    },
  };
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, []);
  return (
    <>
      <Header />

      <div>
        <PrivacyPolicy />
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
