import React,{useState,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import {useOrderRequests} from '../helpers/OrderRequests'
import { DetailContainer } from '../styles/Product'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Currency } from 'react-intl-number-format/dist/index.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Sentry } from "react-activity";

export default function Basket(props) {
  
  const { getMainProducts } = useProductRequests();
  const {getOrders} = useOrderRequests()

  const history=useHistory()
  
  const {basket}=useSelector(state=>state.cart)

  const [discover,setDiscover]=useState([])
  const [orders,setOrders]=useState(null)

  useLayoutEffect(() => {
    
    if(discover.length===0){

      getMainProducts({ type: 1 })
        .then((response) => {
          setDiscover(response.data.data.items);
        })
        .catch((error) => {});

    }
  }, [])

  useLayoutEffect(() => {
    
      if(orders==null){

      getOrders().then((response)=>{
        setOrders(response.data.data)
      }).catch((error)=>{
        console.log("error",error.response)
      })

      }
    }, [])
    const columns = [
      // {
      //   name: "ID",
      //   selector: (row) => <div style={{ maxWidth: 100 }}>{row.orderId}</div>,
      //   sortable: true,
      //   width: "80px",
      // },
      // {
      //   name: "Kod",
      //   selector: (row) => row.orderCode,
      //   sortable: true,
      //   width: "100px",
      // },
      {
        name: "Tarih",
        selector: (row) => row.date,
        sortable: true,
        width: "160px",
      },
      // {
      //   name: "Ad",
      //   selector: (row) => row.detail,
      //   sortable: true,
      // },
      {
        name: "Tutar",
        selector: (row) => (
          <span style={{ color: "green", fontWeight: "bold" }}>
            <>
              {row.totalPrice}
              {row.currencyUnit}
            </>
          </span>
        ),
        sortable: true,
        width: "120px",
      },
      {
        name: "",
        selector: (row) => (
          <Link
            className={row.status + " invoices"}
            to={"/siparisler/" + row.orderId}
          >
            {" "}
            {row.status.toUpperCase()}{" "}
          </Link>
        ),
        sortable: true,
        width: "240px",
      },
    ];

  const [filterText, setFilterText] = React.useState('');
  const [printOn,setPrintOn]=React.useState(false)
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = (orders==null ? []:orders).filter(
		item=>{
    
      return (
      (item.date.toLowerCase().includes(filterText.toLowerCase())) || 
      (item.detail.toLowerCase().includes(filterText.toLowerCase())) || 
      ((item.orderCode+"").toLowerCase().includes(filterText.toLowerCase())) || 
      ((item.orderId+"").toLowerCase().includes(filterText.toLowerCase())) || 
      (item.status.toLowerCase().includes(filterText.toLowerCase())) || 
      ((item.totalPrice+"").toLowerCase().includes(filterText.toLowerCase())) 
      )
    }
	);

  const printPdf=()=>{
    setPrintOn(true)
    setTimeout(() => {
      setPrintOn(false)
    }, 5500);
  }

  const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
      <>
        <button
          style={{
            color: "#000",
            border: "none",
            outline: "1px solid lightblue",
            marginRight: 20,
            height: 30,
            width: 70,
          }}
          onClick={() => {
            if (!printOn) {
              printPdf();
            }
          }}
        >
          {printOn ? <Sentry /> : "PDF"}
        </button>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 0px 6px #000",
            padding: 5,
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            style={{ border: "none", outline: "none", textAlign: "right" }}
            placeholder="Ara"
            className="input-group"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      </>
    );
	}, [filterText, printOn,resetPaginationToggle,orders]);

  return (
    <>
      {printOn ? (
        <>
          <iframe
            src={"yazdir/siparisler"}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
              height: 0,
              width: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Header />

      <div>
        <SlideProductContainer>
          {orders == null ? (
            <>
              <div className="loader">
                <ContentLoader
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" />
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" />
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
              </div>
              <div className="loader" style={{ marginTop: -100 }}>
                <ContentLoader
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" />
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" />
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
              </div>
            </>
          ) : (
            <DataTable
              pagination
              subHeader
              noDataComponent={<>Sipariş kaydı bulunamadı!</>}
              subHeaderComponent={subHeaderComponentMemo}
              columns={columns}
              data={filteredItems}
            />
          )}
        </SlideProductContainer>

        {/* <SlideProductContainer>
          <h1
            style={{
              color: "rgba(255,10,0)", textAlign: "center",
              paddingTop: 40,
              fontSize: 30,
              marginBottom: 8,
              fontWeight: "bold",
            }}
            // onClick={() => history.push("/yeni-urunler")}
          >
            Kampanyalı Ürünler
          </h1>

          {discover.length > 0 ? (
            <SlideProducts items={discover} />
          ) : (
            <div className="loader">
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "20px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
              <ContentLoader
                speed={0.5}
                width={221}
                height={300}
                style={{ marginRight: "40px" }}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                <rect
                  x="0"
                  y="290"
                  rx="3"
                  ry="3"
                  width="221"
                  height="10"
                />{" "}
              </ContentLoader>
            </div>
          )}
        </SlideProductContainer> */}
      </div>

      <BottomNav />

      <Footer />
    </>
  );
}
