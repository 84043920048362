import React, { useState, useEffect } from "react";

import { CartContainer } from "../../styles/Cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faShoppingCart,
  faShoppingBasket,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Currency } from "react-intl-number-format/dist/index.js";
import { useCartRequests } from "../../helpers/CartRequests";
import { toast } from "react-toastify";
import { setBasket } from "../stores/Cart";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ListItem from "./Item";
import ListItemMobile from "./ItemMobile";

export default function CartItems({ cart }) {
  const [total, setTotal] = useState(0);

  const { deleteCart } = useCartRequests();
  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (cart !== null && cart !== undefined) {
      let totalCount = 0;
      cart.map((item) => {
        totalCount += item.salePrice * item.quantity;
        return true;
      });
      setTotal(totalCount);
    }
  }, [cart]);

  const removeCart = () => {
    const loader = toast.loading("Sepet siliniyor...", {
      position: toast.POSITION.BOTTOM_RIGHT,
      isLoading: true,
      closeOnClick: false,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      draggable: false,
    });
    deleteCart()
      .then((response) => {
        dispatch(setBasket(null));
        toast.update(loader, {
          render: response.data.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        });
      })
      .catch((error) => {
        toast.update(loader, {
          render: error.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
        });
      });
  };

  const goHome = () => {
    history.push("/");
  };

  return (
    <CartContainer>
      <div className="list">
        <ul>
          <li className="title">
            <h1>
              {" "}
              <FontAwesomeIcon icon={faShoppingCart} /> Sepetim{" "}
            </h1>

            {cart != null && cart.length > 0 ? (
              <div className="title-control">
                <button
                  className="payment"
                  style={{ marginTop: 10 }}
                  onClick={() => history.goBack()}
                >
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    style={{ marginRight: 20 }}
                  />
                  Alışverişe Devam Et
                </button>
                <div className="removeAll">
                  <span
                    onClick={() => {
                      removeCart();
                    }} 
                    style={{color:'#000'}}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                    <span>Sepeti Boşalt</span>
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </li>
          {cart == null || cart.length < 1 ? (
            <li className="empty-cart">
              <h1>Sepetiniz Boş </h1>
              <button className="payment" onClick={() => goHome()}>
                Alışverişe Devam Et <FontAwesomeIcon icon={faShoppingBasket} />
              </button>
            </li>
          ) : (
            (cart != null ? cart : []).map((item) => {
              return (
                <ListItem key={"it-ct--" + item.slug} cart={cart} item={item} />
              );
            })
          )}
        </ul>
      </div>
      <div className="list-mobile">
        <ul>
          <li className="title">
            <div> Sepetim ( {cart != null ? cart.length : 0} Ürün )</div>
            {cart != null && cart.length > 0 ? (
              <div
                className="title-btn"
                onClick={() => {
                  removeCart();
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            ) : (
              <></>
            )}
          </li>
          {cart == null || cart.length < 1 ? (
            <li className="empty-cart">
              <h1>Sepetiniz Boş </h1>
              <button className="payment" onClick={() => goHome()}>
                Alışverişe Devam Et <FontAwesomeIcon icon={faShoppingBasket} />
              </button>
            </li>
          ) : (
            (cart != null ? cart : []).map((item) => {
              return (
                <ListItemMobile
                  key={"it-ct--" + item.slug}
                  cart={cart}
                  item={item}
                />
              );
            })
          )}
        </ul>
      </div>
      <div className="finally">
        <div className="calculate">
          <div className="head" >
            Toplam {cart != null ? cart.reduce((a, b) => a + b.quantity, 0) : 0}{" "}
            Adet
          </div>
          <div className="total" style={{ color: "#000" }}>
            {/* <CurrencyFormat lang='tr' value={total.toFixed(2)} displayType={'text'}  thousandSeparator={true}  /> */}
            <Currency>{total}</Currency>
          </div>
          {cart == null || cart.length < 1 ? (
            <button className="payment" onClick={() => goHome()}>
              Alışverişe Devam Et
            </button>
          ) : (
            // <button className='payment' onClick={createNewOrder}>Sipariş Oluştur</button>
            <>
              <button
                className="payment confirm"
                onClick={() => history.push("/odeme-sayfasi")}
              >
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: 20 }} />
                Sepeti Onayla
              </button>
            </>
          )}
        </div>
      </div>
      <div className="finally-mobile">
        <div className="calculate">
          <div className="head" style={{ color: "#000" }}>
            {/* <span>
              {cart != null ? cart.reduce((a, b) => a + b.quantity, 0) : 0} Adet
            </span>
            <span>
              {cart != null ? cart.reduce((a, b) => a + (b.quantity*b.unit2coefficient), 0) : 0} Çift
            </span> */}
            <Currency>{total}</Currency>
          </div>
          {/* <div className="total" style={{ color: "#000" }}>
            <CurrencyFormat lang='tr' value={total.toFixed(2)} displayType={'text'}  thousandSeparator={true}  /> 
            <Currency>{total}</Currency>
          </div> */}
          {cart == null || cart.length < 1 ? (
            <button className="payment" onClick={() => goHome()}>
              Alışverişe Devam Et
            </button>
          ) : (
            // <button className='payment' onClick={createNewOrder}>Sipariş Oluştur</button>
            <>
              <button
                className="payment confirm"
                onClick={() => history.push("/odeme-sayfasi")}
              >
                {/* <FontAwesomeIcon icon={faCheck} style={{ marginRight: 20 }} /> */}
                Alışverişi Tamamla
              </button>
            </>
          )}
        </div>
      </div>
    </CartContainer>
  );
}
