import React,{useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { logout } from '../../components/stores/Auth'
import { useHistory } from 'react-router';

export default function LogoutPage() {
  const dispatch = useDispatch()
  const history=useHistory()

  const goLogOut=async()=>{

    dispatch(logout())
    history.push('/giris-yap')
  }

  useEffect(() => {
    goLogOut()
  })
  return (
    <div></div>
  )
}
