import store from './components/stores/index'


const getMainRoutes=async()=>{

  /*
  JSON:
  */
  const routes= await store.getState().auth.routes
  
  return await routes.map(route=>{
    return {
      label:route.label,
      path:route.path,
      exact:route.exact,
      auth:route.auth,
      props:null,
      component:require('./views/'+route.label+'.js').default
    }
  })
  
}

const Routes=getMainRoutes()

export {Routes}