import React from 'react'
import {TitleContainer} from '../../styles/Content'
export default function Title(props) {

  return (
    <TitleContainer>
      {props.text}
    </TitleContainer>
  )
}
