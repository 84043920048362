import React,{useState,useEffect,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SliderSwitch,CarouselContainer,SlideProductContainer, HomeList} from '../styles/Home'
import SliderContainer from '../components/Slider'
import SlideProducts from '../components/Slider/Products'
import SlideBrands from '../components/Slider/Brands'

import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import { useHistory } from 'react-router-dom'

import {Helmet} from "react-helmet";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faArrowDown, faArrowRight, faArrowUp, faBars } from '@fortawesome/free-solid-svg-icons'
import BigCategories from '../components/Navigation/BigCategories'
import Carousel, { consts } from "react-elastic-carousel";
import useWindowDimensions from '../components/useWindowDimensions'

export default function Home(props) {
  
  const { getProducts, getDiscover, getCampaigns, getMainProducts } =
    useProductRequests();
  const width=useWindowDimensions().width
  const {slider}=useSelector(state=>state.site)

  const [open, setOpen] = useState(false);

  const history=useHistory()
  
  const [discover,setDiscover]=useState([])
  const [products, setProducts] = useState(null);
  const [mainProducts, setMainProducts] = useState(null);
  const [brands,setBrands]=useState([])
  const [images,setImages] = useState(null);

  useLayoutEffect(() => {
    
    if (mainProducts ==null) {
      getMainProducts({ type: 0 })
        .then((response) => {
          setMainProducts(response.data.data.items);
        })
        .catch((error) => {

        });

      getMainProducts({ type: 1 })
        .then((response) => {
          setProducts(response.data.data.items);
        })
        .catch((error) => {
          setProducts([])
        });
      getCampaigns("slider").then(res=>{
        setImages(res.data.images);
      })
    }
  }, [])

  const carouselRef = React.useRef(null);
  const [currentIndex,setCurrentIndex]=useState(0)

  return (
    <>
      <Helmet>
        <title>Ana Sayfa</title>
      </Helmet>

      <Header homepage={true} />

      <HomeList open={open}>
        <BigCategories open={open} setOpen={setOpen} />

        <div className="sliders">
          {images != null && images.length > 0 ? (
            <div className="home-slider">
              <Carousel
                ref={carouselRef}
                disableArrowsOnEnd={true}
                autoPlaySpeed={5500}
                enableAutoPlay
                pagination={false}
                renderArrow={({ type, onClick, isEdge }) => {
                  if (width < 1140) {
                    return <></>;
                  }
                  const pointer =
                    type === consts.PREV ? faAngleLeft : faAngleRight;
                  return (
                    <button
                      onClick={onClick}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        left: type == consts.PREV ? "0px" : "unset",
                        right: type != consts.PREV ? "0px" : "unset",
                        zIndex: 2,
                        width: "20%",
                        outline: "none",
                        border: "none",
                        height: "100%",
                        color: "#333",
                        background: "rgba(0,0,0,0.0010151)",
                      }}
                    >
                      <FontAwesomeIcon icon={pointer} />
                    </button>
                  );
                }}
                onChange={() => {
                  setCurrentIndex(carouselRef.current.state.activeIndex);
                }}
              >
                {images.map((x) => {
                  return (
                    <div key={x} className="img">
                      <img src={x} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <></>
          )}
          <SlideProductContainer width={"100%"} left={"0%"}>
            <h1
              style={{ color: "#000", paddingTop: 40,fontSize:45,textAlign:'center',marginBottom:30 }}
              // onClick={() => history.push("/yeni-urunler")}
            >
              En Çok Satanlar
            </h1> 
            {mainProducts != null ? (
              <SlideProducts items={mainProducts} mainProducts={true} />
            ) : (
              <div className="loader">
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "20px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
              </div>
            )}
          </SlideProductContainer>
          {/* <SlideProductContainer width={"100%"} left={"0%"}>
            {products != null && products.length > 0 ? (
              <h1
                style={{
                  color: "rgba(255,10,0)", textAlign: "center",
                  paddingTop: 40,
                  fontSize: 33,
                  marginBottom: 28,
                  marginTop: -70,
                  fontWeight: "bold",
                }}
                // onClick={() => history.push("/yeni-urunler")}
              >
                Kampanyalı Ürünler
              </h1>
            ) : (
              <></>
            )}
            {products != null && products.length > 0 ? (
              <SlideProducts items={products} />
            ) : products == null || products.length == 0 ? (
              <></>
            ) : (
              <div className="loader">
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "20px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="290" rx="3" ry="3" width="221" height="10" />
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
                <ContentLoader
                  speed={0.5}
                  width={221}
                  height={300}
                  style={{ marginRight: "40px" }}
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="0" y="0" rx="0" ry="0" width="221" height="250" />
                  <rect x="0" y="260" rx="3" ry="3" width="221" height="10" />
                  <rect x="0" y="275" rx="3" ry="3" width="221" height="10" />
                  <rect
                    x="0"
                    y="290"
                    rx="3"
                    ry="3"
                    width="221"
                    height="10"
                  />{" "}
                </ContentLoader>
              </div>
            )}
          </SlideProductContainer> */}
        </div>
      </HomeList>

      <BottomNav />

      <Footer />
    </>
  );
}
